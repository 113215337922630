import { useMutation } from '@tanstack/react-query'
import { Button, Form, Input } from 'antd'
import authAPI from '../../apis/auth.api'
import { AppContext } from 'contexts/app.context'
import { useContext } from 'react'
import { FcGoogle } from 'react-icons/fc'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export default function SignIn() {
  const { SetIsAuthenticated, setProfile } = useContext(AppContext)

  const navigate = useNavigate()
  const loginMutation = useMutation({
    mutationFn: authAPI.loginAdmin
  })

  const onFinish = async ({ email, password }) => {
    const resLoginMutation = await loginMutation.mutateAsync({ email, password })
    if (resLoginMutation) {
      // nếu thành công thì set nó thành setIsAuthenticated=true và vào mainlayout
      setProfile(resLoginMutation.data.data.user)
      SetIsAuthenticated(true)
      // và setProfile data User vào localStorage
      toast.success('Đăng nhập thành công', {
        position: 'top-center',
        autoClose: 3000
      })
      navigate('/admin')
    }
  }
  return (
    <div className='mb-16 mt-16 flex h-full w-full items-end justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-center'>
      {/* Sign in section */}
      <div className='mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]'>
        <h4 className='mb-2.5 text-4xl font-bold text-navy-700 dark:text-navy-500'>Đăng nhập</h4>
        <p className='mb-9 ml-1 text-base text-navy-500'>Nhập email và mật khẩu của bạn để đăng nhập!</p>
        <div className='mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800'>
          <div className='rounded-full text-xl'>
            <FcGoogle />
          </div>
          <h5 className='text-sm font-medium text-navy-700 dark:text-white'>Sign In with Google</h5>
        </div>
        <div className='mb-6 flex items-center  gap-3'>
          <div className='h-px w-full bg-navy-500 dark:bg-navy-700' />
          <p className='text-base text-navy-500 dark:text-white'> hoặc </p>
          <div className='h-px w-full bg-navy-500 dark:bg-navy-700' />
        </div>

        <Form
          name='login'
          labelCol={{
            span: 8
          }}
          wrapperCol={{
            span: 16
          }}
          style={{
            maxWidth: 600
          }}
          initialValues={{
            remember: true
          }}
          onFinish={onFinish}
          autoComplete='off'
          className=' w-auto'
        >
          <Form.Item
            label='Email'
            name='email'
            rules={[
              {
                type: 'email',
                message: 'E-mail không đúng định dạng'
              },
              {
                required: true,
                message: 'Vui lòng nhập Email!'
              }
            ]}
          >
            <Input placeholder='Nhập Email' className='h-11' />
          </Form.Item>

          <Form.Item
            label='Password'
            name='password'
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập password!'
              }
            ]}
          >
            <Input.Password className='h-11' placeholder='Nhập Password' />
          </Form.Item>

          {/* Checkbox */}
          <div className='mb-4 flex items-center justify-end px-2'>
            <a className='text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white' href=' '>
              Quên mật khẩu?
            </a>
          </div>

          <Form.Item
            wrapperCol={{
              span: 16
            }}
          >
            <Button
              type='primary'
              htmlType='submit'
              className='linear mt-2 w-full rounded-xl bg-brand-500 py-6 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200'
            >
              Đăng nhập
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
