import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import UserAPI from 'apis/user.api'
import { produce } from 'immer'
import { toast } from 'react-toastify'
import { formatNumber } from 'Util/utils'
import Spinner from 'components/spinner'

const User = () => {

  const [dataListUser, setDataListUser] = useState([])
  const [searchResults, setSearchResults] = useState([])

  const [name, setName] = useState('')

  const { data: getAllUsers, refetch, isLoading } = useQuery({
    queryKey: ['getAllUsers'],
    queryFn: UserAPI.getAllUsers,
    keepPreviousData: true,
    staleTime: 3 * 60 * 1000
  })

  const resgetAllUsersData = getAllUsers?.data.data

  const deleteUsersMutation = useMutation({
    mutationFn: UserAPI.deleteUsers,
    onSuccess: () => {
      refetch()
    }
  })

  useEffect(() => {
    resgetAllUsersData &&
      setDataListUser(
        resgetAllUsersData.map((item) => {
          return {
            ...item,
            checked: false
          }
        })
      )
  }, [resgetAllUsersData])

  useEffect(() => {
    resgetAllUsersData && setSearchResults(resgetAllUsersData)
  }, [resgetAllUsersData])

  // const hanldeOpenDetailUser = (id) => {
  //   navigate('/system/detailUser', {
  //     state: {
  //       detailUser: id
  //     }
  //   })
  // }

  // cơ chế currying
  const handleCheck = (index) => (event) => {
    setDataListUser(
      produce((draft) => {
        draft[index].checked = event.target.checked
      })
    )
  }

  const checkedData = dataListUser.filter((item) => item.checked)

  // delete many items
  const handleDeleteChecked = async () => {
    if (checkedData.length > 0) {
      const confirmation = window.confirm('Bạn có chắc xóa không?')
      if (confirmation) {
        const dataManyChecked = checkedData.map((item) => item._id)

        await deleteUsersMutation.mutateAsync(dataManyChecked, {
          onSuccess: (data) => toast.success(data.data.message)
        })
      }
    }
  }
  const hanldeSearchCode = (e) => {
    const keyword = e.target.value

    if (keyword !== '') {
      const results = dataListUser.filter((user) => {
        return user.customerName.toLowerCase().startsWith(keyword.toLowerCase())
        // Use the toLowerCase() method to make it case-insensitive
      })
      setSearchResults(results)
    } else {
      setSearchResults(dataListUser)
      // If the text field is empty, show all users
    }

    setName(keyword)
  }

  return (
    <>
    {isLoading ? <Spinner/>: (
    <div className='mt-5 grid h-full grid-cols-1 '>
      <div className='mb-4 mt-5 flex flex-col justify-between px-4 md:flex-row md:items-center'>
        <h4 className='ml-1 text-2xl font-bold text-navy-400 dark:text-white'>
          Danh Sách Khách Hàng: <span className='text-red-500'> {searchResults && searchResults.length}</span>
        </h4>

        <div className='mt-4 flex items-end justify-end md:mt-0 md:justify-end'>
          <input
            type='search'
            id='table-search-users'
            className='block w-80 border border-gray-300 bg-gray-50 p-2 pl-10 text-sm text-gray-900 outline-none focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
            placeholder='Tìm Kiếm Khác Hàng'
            value={name}
            onChange={hanldeSearchCode}
          />
          <button className='rounded-tr-lg rounded-br-lg bg-blue-500 py-2 px-5' type='submit'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='h-[22px] w-5'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z'
              />
            </svg>
          </button>
        </div>
      </div>
      <div className='flex justify-end'>
        <button className='my-2 flex rounded-lg bg-red-500 py-4 px-2 text-xs' onClick={handleDeleteChecked}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='h-4 w-4'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
            />
          </svg>
          <span>Xóa mục chọn</span>
        </button>
      </div>
      <div className='py-2'>
        <div className=' relative max-h-[500px] overflow-x-auto  overflow-y-auto shadow-md sm:rounded-lg '>
          <table className='w-full p-5 text-left text-sm text-gray-500 dark:text-gray-400'>
            <thead className='bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400'>
              <tr>
                <th scope='col' className='w-1/12 p-4'>
                  <div className='flex items-center'>
                    <input
                      id='checkbox-all-search'
                      type='checkbox'
                      className='h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-800'
                    />
                    <label htmlFor='checkbox-all-search' className='sr-only'>
                      checkbox
                    </label>
                  </div>
                </th>
                <th scope='col' className='w-2/12 px-6 py-3'>
                  Khách Hàng
                </th>
                <th scope='col' className='w-2/12 px-6 py-3'>
                  Địa Điểm
                </th>
                <th scope='col' className='w-1/12 px-6 py-3'>
                  Tổng Đơn Hàng
                </th>
                <th scope='col' className='w-2/12 px-6 py-3'>
                  Đơn Hàng Gần Nhất
                </th>
                <th scope='col' className='w-2/12 px-6 py-3'>
                  Tổng
                </th>
                <th scope='col' className='w-2/12 px-4 py-3'>
                  Thông Tin
                </th>
              </tr>
            </thead>
            <tbody>
              {searchResults && searchResults.length > 0 ? (
                searchResults.map((item, index) => (
                  <tr
                    className='border-b bg-white hover:cursor-pointer hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600'
                    key={item._id}
                  >
                    <td className='w-4 p-4'>
                      <div className='flex items-center'>
                        <input
                          id='checkbox-table-search-1'
                          type='checkbox'
                          checked={item.checked}
                          onChange={handleCheck(index)}
                          className='h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-800'
                        />
                        <label htmlFor='checkbox-table-search-1' className='sr-only'>
                          checkbox
                        </label>
                      </div>
                    </td>

                    <td className='px-6 py-4 font-bold text-navy-400'>{item.customerName}</td>
                    <td className='line-clamp-3 px-6 py-4'>{item.address}</td>
                    <td className='px-6 py-4'>{item.totalOrders}</td>
                    <td className='px-6 py-4'>{item.latestOrder ? item.latestOrder : '_'}</td>
                    <td className='px-6 py-4'>{` ₫${formatNumber(item.totalValue)}`}</td>
                    <td className='px-6 py-4 '>
                      <Link
                        to={`/admin/user/${item._id}`}
                        className='rounded-sm bg-blue-500 px-4 py-2 text-xs text-white hover:bg-orange-400'
                        // onClick={() => hanldeOpenDetail(item._id)}
                      >
                        Xem Chi Tiết
                      </Link>
                      {/* <button
                        className='mx-2 rounded-sm bg-blue-500 py-2 px-3 text-xs text-white hover:bg-orange-400'
                        onClick={() => hanldeOpenDetailUser(item._id)}
                      >
                        Xem Chi Tiết
                      </button> */}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  Danh sách rỗng...
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    )}
    </>
  )
}

export default User
