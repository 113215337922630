/* eslint-disable */
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { MdOutlineAlignHorizontalLeft, MdOutlineReceiptLong } from 'react-icons/md'
// chakra imports

export function SidebarLinks(props) {
  // Chakra color mode
  let location = useLocation()

  const { routes } = props
  const [isActive, setIsActive] = useState(false)
  const handleCheck = () => {
    setIsActive((prev) => !prev)
  }
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName)
  }

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (
        route.layout === '/admin' &&
        route.path !== 'orders/:detailStatus' &&
        route.path !== 'categories' &&
        route.path !== 'products' &&
        route.path !== 'products/create/:idCategory' &&
        route.path !== 'products/update/:idProduct' &&
        route.path !== 'user/:detailUser' &&
        route.path !== 'categoryBlog/:idBlog' &&
        route.path !== 'categoryBlog/:idBlog/new_blog' &&
        route.path !== 'categoryBlog/:idBlog/:codeBlog'
      ) {
        if (route.path === 'totalproduct') {
          return (
            <Link key={index} to='#' onClick={handleCheck}>
              <div className='relative mb-3 flex hover:cursor-pointer'>
                <li className='my-[3px] flex cursor-pointer items-center px-8' key={index}>
                  <span
                    className={`${
                      activeRoute(route.path) === true
                        ? 'font-bold text-brand-500 dark:text-white'
                        : 'font-medium text-gray-600'
                    }`}
                  >
                    {route.icon ? route.icon : <DashIcon />}{' '}
                  </span>
                  <p
                    className={`leading-1 ml-4 flex ${
                      activeRoute(route.path) === true
                        ? 'font-bold text-navy-700 dark:text-white'
                        : 'font-medium text-gray-600'
                    }`}
                  >
                    {route.name}
                  </p>
                </li>
                {activeRoute(route.path) ? (
                  <div className='absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400' />
                ) : null}
              </div>

              <div className={isActive ? '' : 'hidden'}>
                <Link to='/admin/categories'>
                  <div className='relative mb-3 ml-5 flex hover:cursor-pointer'>
                    {activeRoute(route.path) ? (
                      <div className='absolute left-5 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400' />
                    ) : null}
                    <li className='my-[3px] flex cursor-pointer items-center px-8'>
                      <span className='font-medium text-gray-600'>
                        <MdOutlineAlignHorizontalLeft className='h-6 w-6' />
                      </span>
                      <p className='leading-1 ml-4 flex font-medium text-gray-600'>Quản Lý Danh Mục</p>
                    </li>
                  </div>
                </Link>
                <Link to='/admin/products'>
                  <div className='relative mb-3 ml-5 flex hover:cursor-pointer'>
                    {activeRoute(route.path) ? (
                      <div className='absolute left-5 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400' />
                    ) : null}
                    <li className='my-[3px] flex cursor-pointer items-center px-8'>
                      <span className='font-medium text-gray-600'>
                        <MdOutlineReceiptLong className='h-6 w-6' />
                      </span>
                      <p className='leading-1 ml-4 flex font-medium text-gray-600'>Quản Lý Sản Phẩm</p>
                    </li>
                  </div>
                </Link>
              </div>
            </Link>
          )
        } else {
          return (
            <Link key={index} to={route.layout + '/' + route.path}>
              <div className='relative mb-3 flex hover:cursor-pointer'>
                <li className='my-[3px] flex cursor-pointer items-center px-8' key={index}>
                  <span
                    className={`${
                      activeRoute(route.path) === true
                        ? 'font-bold text-brand-500 dark:text-white'
                        : 'font-medium text-gray-600'
                    }`}
                  >
                    {route.icon ? route.icon : <DashIcon />}{' '}
                  </span>
                  <p
                    className={`leading-1 ml-4 flex ${
                      activeRoute(route.path) === true
                        ? 'font-bold text-navy-700 dark:text-white'
                        : 'font-medium text-gray-600'
                    }`}
                  >
                    {route.name}
                  </p>
                </li>
                {activeRoute(route.path) ? (
                  <div className='absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400' />
                ) : null}
              </div>
            </Link>
          )
        }
      }
    })
  }
  // BRAND
  return createLinks(routes)
}

export default SidebarLinks
