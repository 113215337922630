import React from 'react'
// Admin Imports
import MainDashboard from 'views/admin/default'
import NFTorder from 'views/admin/order'
import User from 'views/admin/user'

import SignIn from 'views/auth/SignIn'
// Icon Imports
import { MdHome, MdOutlineShoppingCart, MdPerson, MdLock, MdNewspaper, MdBento } from 'react-icons/md'
import Category from 'views/admin/category'
import Product from 'views/admin/product'
import DetailOrder from 'views/admin/detailOrder'
import CreateProduct from 'views/admin/product/components/createProduct'
import UpdateProduct from 'views/admin/product/components/updateProduct'
import DetailUser from 'views/admin/user/components/DetailUser'
import CategoryBlog from 'views/admin/blog/categoryBlog'
import Blog from 'views/admin/blog/components/blog'
import CreateAndUpdateBlog from 'views/admin/blog/components/createAndUpdateBlog'

const routes = [
  {
    name: 'Xem Thông Tin',
    layout: '/admin',
    path: 'default',
    icon: <MdHome className='h-6 w-6' />,
    component: <MainDashboard />
  },
  {
    name: 'Quản Lý Đơn Hàng',
    layout: '/admin',
    path: 'orders',
    icon: <MdOutlineShoppingCart className='h-6 w-6' />,
    component: <NFTorder />,
    secondary: true
  },
  {
    name: 'Quản Lý chi tiết Đơn Hàng',
    layout: '/admin',
    path: 'orders/:detailStatus',
    icon: <MdOutlineShoppingCart className='h-6 w-6' />,
    component: <DetailOrder />
  },
  {
    name: 'Tất Cả Sản Phẩm',
    layout: '/admin',
    icon: <MdBento className='h-6 w-6' />,
    path: 'totalproduct'
  },
  {
    name: 'Quản Lý Danh Mục',
    layout: '/admin',
    path: 'categories',
    icon: <MdPerson className='h-6 w-6' />,
    component: <Category />
  },
  {
    name: 'Quản Lý Sản Phẩm',
    layout: '/admin',
    path: 'products',
    icon: <MdPerson className='h-6 w-6' />,
    component: <Product />
  },
  {
    name: 'Tạo Mới Sản Phẩm',
    layout: '/admin',
    path: 'products/create/:idCategory',
    icon: <MdPerson className='h-6 w-6' />,
    component: <CreateProduct />
  },
  {
    name: 'Cập Nhật Sản Phẩm',
    layout: '/admin',
    path: 'products/update/:idProduct',
    icon: <MdPerson className='h-6 w-6' />,
    component: <UpdateProduct />
  },
  {
    name: 'Quản Lý Người Dùng',
    layout: '/admin',
    path: 'user',
    icon: <MdPerson className='h-6 w-6' />,
    component: <User />
  },
  {
    name: 'Quản Lý Thông Tin Người Dùng',
    layout: '/admin',
    path: 'user/:detailUser',
    icon: <MdPerson className='h-6 w-6' />,
    component: <DetailUser />
  },
  {
    name: 'Quản Lý Tin Tức',
    layout: '/admin',
    path: 'categoryBlog',
    icon: <MdNewspaper className='h-6 w-6' />,
    component: <CategoryBlog />
  },
  {
    name: 'Quản Lý Thông Tin Tin Tức',
    layout: '/admin',
    path: 'categoryBlog/:idBlog',
    icon: <MdNewspaper className='h-6 w-6' />,
    component: <Blog />
  },
  {
    name: 'Thêm Thông Tin Tin Tức',
    layout: '/admin',
    path: 'categoryBlog/:idBlog/new_blog',
    icon: <MdNewspaper className='h-6 w-6' />,
    component: <CreateAndUpdateBlog />
  },
  {
    name: 'Cập Nhật Thông Tin Tin Tức',
    layout: '/admin',
    path: 'categoryBlog/:idBlog/:codeBlog',
    icon: <MdNewspaper className='h-6 w-6' />,
    component: <CreateAndUpdateBlog />
  },
  {
    name: 'Sign In',
    layout: '/auth',
    path: 'sign-in',
    icon: <MdLock className='h-6 w-6' />,
    component: <SignIn />
  }
]
export default routes
