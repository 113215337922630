import { useMutation, useQueryClient } from '@tanstack/react-query'
import ProductAPI from 'apis/products.api'
import Spinner from 'components/spinner'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { formatNumber } from 'Util/utils'

export default function RenderProduct({ idCategory, name, products }) {
  const [isToggle, setIsToggle] = useState(true)
  const queryClient = useQueryClient()
  const deleteProductMutation = useMutation({
    mutationFn: ProductAPI.deleteProduct,
    onSuccess: () => {
      queryClient.invalidateQueries('getProducts')
    }
  })
  const handleToggle = () => {
    setIsToggle(!isToggle)
  }
  const handleDeleteProduct = async (idProduct) => {
    const confirmation = window.confirm('Bạn có chắc Xoá Sản Phẩm này không?')
    if (confirmation) {
      const resDeleteCategory = await deleteProductMutation.mutateAsync(idProduct)
      if (resDeleteCategory) {
        toast.success(resDeleteCategory?.data.message, {
          position: 'top-center',
          autoClose: 1000
        })
      } else {
        toast.error(resDeleteCategory?.data.message, {
          position: 'top-center',
          autoClose: 1000
        })
      }
    }
  }
  const reProducts = [...products].reverse()
  return (
    <>
      {deleteProductMutation.isPending ? (
        <Spinner />
      ) : (
        <>
          <tr className=' border-t-2 bg-gray-300 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400 '>
            <th colSpan='8' className='cursor-pointer px-6 py-2'>
              <button className='flex rounded-md bg-blue-200 px-5 py-2' onClick={handleToggle}>
                <span>{name}</span>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='ml-2 h-4 w-4'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5'
                  />
                </svg>
              </button>
            </th>
            <th colSpan='1' className='px-6 py-2'>
              <Link to={`/admin/products/create/${idCategory}`}>
                <button className='rounded-full bg-blue-300 p-2 text-sm'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    className='h-6 w-6'
                  >
                    <path strokeLinecap='round' strokeLinejoin='round' d='M12 4.5v15m7.5-7.5h-15' />
                  </svg>
                </button>
              </Link>
            </th>
          </tr>
          {isToggle &&
            reProducts.map((product) => (
              <tr
                className='border-b bg-white transition-opacity duration-1000 hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600'
                key={product._id}
              >
                <th className='line-clamp-5  px-6 font-medium text-gray-900 dark:text-white'>{product.name}</th>
                <td className='px-6 py-2'>{`đ${formatNumber(product.price_before_discount)}`}</td>
                <td className='px-6 py-2'>{product.price !== 0 ? `đ${formatNumber(product.price)}` : '_'}</td>
                <td className='px-6 py-2'>{formatNumber(product.quantity)}</td>
                <td className='px-6 py-2'>{formatNumber(product.sold ? product.sold : 0)}</td>
                <td className='line-clamp-2 '>{product.descriptionText}</td>
                <td className='py-2'>
                  <img src={product.image.path} alt={product.name} className='h-20 w-20' />
                </td>
                <td className='flex flex-wrap justify-around py-4 '>
                  {product.images.map((image, index) => (
                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                    <img key={index} src={image.path} alt={`image ${index} `} className='h-10 w-10 object-cover' />
                  ))}
                </td>
                <td className='py-4 '>
                  <button
                    className='underline-none mb-2 mr-3 flex w-full flex-col rounded-sm p-2 text-center text-xs text-blue-600 hover:bg-gray-200 dark:text-blue-500'
                    onClick={() => handleDeleteProduct(product._id)}
                  >
                    Xóa
                  </button>
                  <Link
                    to={`/admin/products/update/${product._id}`}
                    className='underline-none flex flex-col rounded-sm p-2 text-xs text-blue-600 hover:bg-gray-200 dark:text-blue-500'
                  >
                    Chỉnh Sửa
                  </Link>
                </td>
              </tr>
            ))}
        </>
      )}
    </>
  )
}
