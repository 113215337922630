import { useQuery } from '@tanstack/react-query'
import { Select } from 'antd'
import ProductAPI from 'apis/products.api'
import { useEffect, useState } from 'react'
import RenderProduct from './components/renderProduct'
import Spinner from 'components/spinner'

const Product = () => {
  // getAllProducts
  const { data: ProductData, isLoading } = useQuery({
    queryKey: ['getProducts'],
    queryFn: ProductAPI.getAllProducts
  })
  const [options, setOptions] = useState([])
  const [dataFilter, setdataFilter] = useState([])

  const resProductData = ProductData?.data?.data

  useEffect(() => {
    resProductData &&
      setOptions(
        resProductData.map((option) => ({
          value: option._id,
          label: option.name
        }))
      )
  }, [resProductData])

  const handleChange = (value) => {
    setdataFilter(value)
  }

  const dataAfterFilter = resProductData && resProductData.filter((item) => dataFilter.includes(item._id))

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className='mt-5 grid h-full grid-cols-1 '>
          <div className='mt-3'>
            <div className='flex justify-end p-2'>
              <Select
                mode='tags'
                style={{
                  width: '50%'
                }}
                onChange={handleChange}
                tokenSeparators={[',']}
                options={options}
                placeholder='Chọn Danh Mục Sản Phẩm'
              />
            </div>
            <div className='w-10px h-[2px] bg-white' />
          </div>

          <div className='py-2'>
            <div className=' relative max-h-[500px] overflow-x-auto  overflow-y-auto shadow-md sm:rounded-lg '>
              <table className='w-full text-left text-sm text-gray-500 dark:text-gray-400'>
                <thead className='sticky top-0 z-30 bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400'>
                  <tr>
                    <th scope='col' className='px-6 py-3'>
                      Tên Sản Phẩm
                    </th>
                    <th scope='col' className='px-6 py-3'>
                      Giá
                    </th>
                    <th scope='col' className='px-6 py-3'>
                      Giá Khuyến Mãi
                    </th>
                    <th scope='col' className='px-6 py-3'>
                      Số Lượng Kho
                    </th>
                    <th scope='col' className='px-6 py-3'>
                      Đã Bán
                    </th>
                    <th scope='col' className='px-6 py-3'>
                      Mô Tả
                    </th>
                    <th scope='col' className='px-6 py-3'>
                      Ảnh Chính
                    </th>
                    <th scope='col' className='px-6 py-3'>
                      Ảnh Phụ
                    </th>
                    <th scope='col' className='px-6 py-3'>
                      Hành Động
                    </th>
                  </tr>
                </thead>
                {dataFilter.length > 0 ? (
                  <tbody>
                    {dataAfterFilter &&
                      dataAfterFilter.map((item) => (
                        <RenderProduct key={item._id} idCategory={item._id} name={item.name} products={item.products} />
                      ))}
                  </tbody>
                ) : (
                  <tbody>
                    {resProductData &&
                      resProductData.map((item) => (
                        <RenderProduct key={item._id} idCategory={item._id} name={item.name} products={item.products} />
                      ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Product
