import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Form, Input, Modal } from 'antd'
import CategoryAPI from 'apis/category.api'
import Spinner from 'components/spinner'
import { useState } from 'react'
import { toast } from 'react-toastify'

const Category = () => {
  const [openCreate, setOpenCreate] = useState(false)
  const [openUpdate, setOpenUpdate] = useState(false)
  const [form] = Form.useForm()
  const [name, setName] = useState('')
  const [nameUpdate, setNameUpdate] = useState('')

  const [idCategory, setIdCategory] = useState('')

  const { data: CategoryData, refetch } = useQuery({
    queryKey: ['getCategories'],
    queryFn: CategoryAPI.getAllCategories
  })

  const deleteCategoryMutation = useMutation({
    mutationFn: CategoryAPI.deleteCategory,
    onSuccess: () => {
      refetch()
    }
  })
  const createCategoryMutation = useMutation({
    mutationFn: CategoryAPI.createCategory,
    onSuccess: () => {
      refetch()
    }
  })
  const updateCategoryMutation = useMutation({
    mutationFn: CategoryAPI.updateCategory,
    onSuccess: () => {
      refetch()
    }
  })
  const handleDeleteCategories = async (id) => {
    const confirmation = window.confirm('Bạn có chắc Xoá danh Mục và Sản Phẩm trong Danh Mục đó không?')
    if (confirmation) {
      const resDeleteCategory = await deleteCategoryMutation.mutateAsync(id)
      if (resDeleteCategory) {
        toast.success(resDeleteCategory?.data.message, {
          position: 'top-center',
          autoClose: 1000
        })
      }
    }
  }
  const handleCreate = async () => {
    const resCreateCategoryMutation = await createCategoryMutation.mutateAsync({ name: name })
    toast.success(resCreateCategoryMutation?.data.message, {
      position: 'top-center',
      autoClose: 1000
    })
    setName('')
    setOpenCreate(false)
  }
  const resCategoryData = CategoryData?.data.data
  const showModal = () => {
    setOpenCreate(!openCreate)
  }
  const showModalForUpdate = (id, nameCategory) => () => {
    form.setFieldsValue({
      nameCategory: nameCategory
    })
    setIdCategory(id)
    // setNameUpdate(nameCategory)
    // setIdCategory(idCategory)
    setOpenUpdate(!openUpdate)
  }
  const handleCancel = () => {
    setOpenCreate(false)
    setOpenUpdate(false)
    setName('')
  }
  const handleInputChange = (e) => {
    setName(e.target.value)
  }
  const handleInputUpdateChange = (e) => {
    setNameUpdate(e.target.value)
  }
  // updatePurchaseMutation.mutate({ product_id: purchase.product._id, buy_count: value })
  const handleUpdate = async () => {
    const resupdateCategoryMutation = await updateCategoryMutation.mutateAsync({
      id: idCategory,
      name: nameUpdate
    })
    toast.success(resupdateCategoryMutation?.data.message, {
      position: 'top-center',
      autoClose: 1000
    })
    setOpenUpdate(!openUpdate)
  }
  return (
    <div className='mt-5 grid h-full grid-cols-1 '>
      <div className='px-2'>
        <div className='flex justify-end'>
          <Button type='default' onClick={showModal} className='h-10 bg-blue-300'>
            Tạo Danh Mục
          </Button>
          <Modal title='Tạo Danh Mục' open={openCreate} footer={null} onCancel={handleCancel}>
            <Form onFinish={handleCreate}>
              <Form.Item
                name='name'
                rules={[
                  {
                    required: true,
                    message: 'Nhập Tên Danh Mục là bắt buộc'
                  }
                ]}
              >
                <Input placeholder='Nhập Tên Danh Mục' name='nameCategory' onChange={handleInputChange} value={name} />
              </Form.Item>
              <Form.Item>
                <Button className='float-right bg-blue-400' type='primary' htmlType='submit'>
                  Lưu
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </div>
        {resCategoryData ? (
          resCategoryData.length > 0 ? (
            <div className='relative m-2 overflow-x-auto shadow-md sm:rounded-lg'>
              <table className='w-full text-left text-sm text-gray-500 dark:text-gray-400'>
                <thead className='bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400'>
                  <tr>
                    <th scope='col' className='px-6 py-3'>
                      STT
                    </th>
                    <th scope='col' className='px-6 py-3'>
                      Tên Danh Mục
                    </th>
                    <th scope='col' className='px-6 py-3'>
                      Hành Động
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {resCategoryData &&
                    resCategoryData.map((item, index) => (
                      <tr
                        className='border-b bg-white hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600'
                        key={item._id}
                      >
                        <th
                          scope='row'
                          className='whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white'
                        >
                          {index + 1}
                        </th>
                        <td className='px-6 py-4 capitalize'>{item.name}</td>

                        <td className='flex px-3 py-4'>
                          <button
                            className='mr-3 font-medium text-blue-600 hover:underline dark:text-blue-500'
                            onClick={() => handleDeleteCategories(item._id)}
                          >
                            Xóa
                          </button>

                          <Button
                            type='default'
                            onClick={showModalForUpdate(item._id, item.name)}
                            className='cursor-pointer font-medium text-blue-600 hover:underline dark:text-blue-500'
                          >
                            Chỉnh Sửa
                          </Button>

                          <Modal title='Cập Nhật Danh Mục' open={openUpdate} footer={null} onCancel={handleCancel}>
                            <Form onFinish={handleUpdate} form={form} initialValues={item}>
                              <Form.Item
                                name='nameCategory'
                                rules={[
                                  {
                                    required: true,
                                    message: 'Nhập Tên Danh Mục là bắt buộc'
                                  }
                                ]}
                              >
                                <Input
                                  placeholder='Cập Nhật Tên Danh Mục'
                                  name='nameCategory'
                                  onChange={handleInputUpdateChange}
                                  value={nameUpdate}
                                />
                              </Form.Item>
                              <Form.Item>
                                <Button className='float-right bg-blue-400' type='primary' htmlType='submit'>
                                  Lưu
                                </Button>
                              </Form.Item>
                            </Form>
                          </Modal>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div>
              <p>Danh sách rỗng...</p>
            </div>
          )
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  )
}

export default Category
