import { Link } from 'react-router-dom'

export default function NotFound404() {
  return (
    <main className='flex h-screen w-full flex-col items-center justify-center bg-[#1A2238]'>
      <h1 className='text-[150px] font-black tracking-widest text-white'>404</h1>
      <div className='absolute rotate-12 rounded bg-orange-400 px-2 text-xl'>Page Not Found</div>
      <button className='mt-5'>
        <Link
          to='/admin'
          className='text-obg-orange group relative inline-block text-sm font-medium focus:outline-none focus:ring active:text-orange-500'
        >
          <span className='absolute inset-0 translate-x-0.5 translate-y-0.5 bg-orange-500 transition-transform group-hover:translate-x-0 group-hover:translate-y-0' />
          <div className='border-current bg-slate-500 relative block border px-8 py-3'>
            <span>Go Home</span>
          </div>
        </Link>
      </button>
    </main>
  )
}
