import React, { useContext } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
// import RtlLayout from 'layouts/rtl'
import AdminLayout from 'layouts/admin'
import AuthLayout from 'layouts/auth'
import NotFound404 from 'layouts/notFound404'
import { AppContext } from 'contexts/app.context'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
const App = () => {
  const { isAuthenticated } = useContext(AppContext)
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path='admin/*' element={isAuthenticated ? <AdminLayout /> : <Navigate to='/auth/sign-in' replace />} />
        <Route path='auth/*' element={<AuthLayout />} />
        {/* <Route path='rtl/*' element={<RtlLayout />} /> */}
        <Route path='/' element={<Navigate to='/admin' replace />} />
        <Route path='*' element={<NotFound404 />} />
      </Routes>
    </>
  )
}

export default App
