import React, { useContext } from 'react'
import Dropdown from 'components/dropdown'
import { FiAlignJustify, FiHome } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { FiSearch } from 'react-icons/fi'
import { RiMoonFill, RiSunFill } from 'react-icons/ri'
import avatar from 'assets/img/avatars/logo.png'
import { AppContext } from 'contexts/app.context'
import { useMutation } from '@tanstack/react-query'
import authAPI from 'apis/auth.api'
import { toast } from 'react-toastify'

const Navbar = (props) => {
  const { onOpenSidenav, brandText } = props
  const [darkmode, setDarkmode] = React.useState(false)

  const { SetIsAuthenticated, setProfile } = useContext(AppContext)

  const logoutMutation = useMutation({
    mutationFn: () => authAPI.logoutAdmin(),
    onSuccess: () => {
      SetIsAuthenticated(false)
      setProfile(null)
      toast.success('Logout Thành Công')
    }
  })

  const handleLogout = () => {
    logoutMutation.mutate()
  }
  return (
    <nav className='sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]'>
      <div className='ml-[6px]'>
        <div className='flex h-6 w-[224px] items-center pt-1'>
          <FiHome className='h-6 w-6 pb-1 dark:text-white' />
          <span className='mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white'> / </span>

          <Link
            className='text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white'
            to='#'
          >
            {brandText}
          </Link>
        </div>
        <p className='shrink text-[33px] capitalize text-navy-700 dark:text-white'>
          <Link to='#' className='font-bold capitalize hover:text-navy-700 dark:hover:text-white'>
            {brandText}
          </Link>
        </p>
      </div>

      <div className='relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2'>
        <div className='flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]'>
          <p className='pl-3 pr-2 text-xl'>
            <FiSearch className='h-4 w-4 text-gray-400 dark:text-white' />
          </p>
          <input
            type='text'
            placeholder='Search...'
            className='block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit'
          />
        </div>
        <span className='flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden' onClick={onOpenSidenav}>
          <FiAlignJustify className='h-5 w-5' />
        </span>
        {/* start Notification */}
        {/* <Dropdown
          button={
            <p className='cursor-pointer'>
              <IoMdNotificationsOutline className='h-5 w-5 text-gray-600 dark:text-white' />
            </p>
          }
          animation='origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out'
          children={
            <div className='flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]'>
              <div className='flex items-center justify-between'>
                <p className='text-base font-bold text-navy-700 dark:text-white'>Notification</p>
                <p className='text-sm font-bold text-navy-700 dark:text-white'>Mark all read</p>
              </div>

              <button className='flex w-full items-center'>
                <div className='flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-brandLinear to-brand-500 py-4 text-2xl text-white'>
                  <BsArrowBarUp />
                </div>
                <div className='ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm'>
                  <p className='mb-1 text-left text-base font-bold text-gray-900 dark:text-white'>
                    New Update: Horizon UI Dashboard PRO
                  </p>
                  <p className='font-base text-left text-xs text-gray-900 dark:text-white'>
                    A new update for your downloaded item is available!
                  </p>
                </div>
              </button>

              <button className='flex w-full items-center'>
                <div className='flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-brandLinear to-brand-500 py-4 text-2xl text-white'>
                  <BsArrowBarUp />
                </div>
                <div className='ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm'>
                  <p className='mb-1 text-left text-base font-bold text-gray-900 dark:text-white'>
                    New Update: Horizon UI Dashboard PRO
                  </p>
                  <p className='font-base text-left text-xs text-gray-900 dark:text-white'>
                    A new update for your downloaded item is available!
                  </p>
                </div>
              </button>
            </div>
          }
          classNames={'py-2 top-4 -left-[230px] md:-left-[440px] w-max'}
        /> */}

        <div
          className='cursor-pointer text-gray-600'
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove('dark')
              setDarkmode(false)
            } else {
              document.body.classList.add('dark')
              setDarkmode(true)
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className='h-5 w-5 text-gray-600 dark:text-white' />
          ) : (
            <RiMoonFill className='h-5 w-5 text-gray-600 dark:text-white' />
          )}
        </div>
        {/* Profile & Dropdown */}
        <Dropdown
          button={<img className='h-10 w-10 rounded-full' src={avatar} alt='goomohome' />}
          children={
            <div className='flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none'>
              <div className='p-4'>
                <div className='flex items-center gap-2'>
                  <p className='text-sm font-bold text-navy-700 dark:text-white'>👋 Xin Chào, Admin</p>{' '}
                </div>
              </div>
              <div className='h-px w-full bg-gray-200 dark:bg-white/20 ' />

              <div className='flex flex-col p-4'>
                <button
                  onClick={handleLogout}
                  className='mt-3 text-sm font-medium text-red-500 transition duration-150 ease-out hover:text-red-500 hover:ease-in'
                >
                  Log Out
                </button>
              </div>
            </div>
          }
          classNames={'py-2 top-8 -left-[180px] w-max'}
        />
      </div>
    </nav>
  )
}

export default Navbar
